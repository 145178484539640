import React, { useEffect, useState } from "react";
import {
  Box,
  Typography,
  Grid,
  Avatar,
  Button,
  IconButton,
  TextField,
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import backButton from "../images/icons/backIcon.svg";
import { useNavigate } from "react-router-dom";
import vegIcon from "../images/icons/vegIcon.svg";
import OrderSummary from "../components/OrderSummary";
import { useLocation } from "react-router-dom";
import "./DealBanner.css"; // Assuming you are importing CSS from an external file
import foodIcon from "../images/icons/food.svg";
import baverageIcon from "../images/icons/baverage.svg";
import nonVegIcon from "../images/icons/ic_non_veg.svg";
import nonAlcholicIcon from "../images/icons/ic_non_alcoholic.svg";
import alcholicIcon from "../images/icons/ic_alcoholic.svg";
import trackingIcon from "../images/icons/trackingIcon.svg";
import SearchImage from "../images/icons/search.svg";
import plusButton from "../images/icons/plus.svg";
import minusButton from "../images/icons/minus.svg";
import { useDispatch, useSelector } from "react-redux";
import store, {
  setFoodDetails,
  addOrderItem,
  incrementOrderItem,
  decrementOrderItem,
} from "../Redux/store";
import { FoodDetailModal } from "../components/FoodDetailModal";
import S3ImageAmplify from "../config/S3ImageAmplify";
import RenderNoResults from "../components/RenderNoResults";
import IconHandler from "../components/IconHandler";

const FoodItemList = () => {
  const dispatch = useDispatch();
  const orderItems = useSelector((state) => state.food.orderItems);
  const [selectedItem, setSelectedItem] = useState(null);
  const [searchQuery, setSearchQuery] = useState("");
  const [isSearchVisible, setIsSearchVisible] = useState(false);
  const [isOrderSummaryVisible, setIsOrderSummaryVisible] = useState(
    orderItems.length > 0
  );
  const [icon, setIcons] = useState(foodIcon);
  const location = useLocation();
  const { datas, category } = location.state || {};

  useEffect(() => {
    if (category && category.name && category.parent_sub_category) {
      updateIcon(category.name, category.parent_sub_category);
    }
  }, [category]);

  // Function to update the icon based on the category name
  function updateIcon(categoryName, categoryParentSubCategory) {
    const isBeverage = categoryName.toLowerCase().includes("beverage");
    const isExp = categoryParentSubCategory.toLowerCase().includes("exp");
    setIcons(isBeverage ? baverageIcon : isExp ? trackingIcon : foodIcon);
  }

  const filteredItems = datas?.filter((item) =>
    item.name.toLowerCase().includes(searchQuery.toLowerCase())
  );

  const filteredItemsBestSeller = filteredItems.filter(
    (item) => item.status === "bestseller"
  );

  const handleIncrement = (item) => {
    let addOn = [];

    // Check if add_on_checklist is not empty and valid JSON
    if (item.add_on_checklist && item.add_on_checklist !== "") {
      try {
        // Try parsing the JSON, default to empty array if parsing fails
        addOn = JSON.parse(item.add_on_checklist) || [];
      } catch (error) {
        console.error("Error parsing add_on_checklist:", error);
        addOn = []; // Default to empty array if there's an error
      }
    }

    if (addOn.length === 0) {
      console.log("if");
      dispatch(incrementOrderItem(item));
      console.log(orderItems);
    } else {
      console.log("else");
      dispatch(setFoodDetails(item));
      setSelectedItem(item);
    }
  };

  const handleDecrement = (item) => {
    // Logic to decrement the item count in the order

    let addOn = [];

    // Check if add_on_checklist is not empty and valid JSON
    if (item.add_on_checklist && item.add_on_checklist !== "") {
      try {
        // Try parsing the JSON, default to empty array if parsing fails
        addOn = JSON.parse(item.add_on_checklist) || [];
      } catch (error) {
        console.error("Error parsing add_on_checklist:", error);
        addOn = []; // Default to empty array if there's an error
      }
    }

    if (addOn.length === 0) {
      dispatch(decrementOrderItem(item));
      // Access the updated state to check if `orderItems` is empty
      const state = store.getState(); // Use your Redux store instance
      if (state.food.orderItems.length === 0) {
        setIsOrderSummaryVisible(false); // Hide the order summary
      }
    } else {
      dispatch(setFoodDetails(item));
      setSelectedItem(item);
    }
  };

  const handleAddToOrder = (item) => {
    let addOn = [];

    // Check if add_on_checklist is not empty and valid JSON
    if (item.add_on_checklist && item.add_on_checklist !== "") {
      try {
        // Try parsing the JSON, default to empty array if parsing fails
        addOn = JSON.parse(item.add_on_checklist) || [];
      } catch (error) {
        console.error("Error parsing add_on_checklist:", error);
        addOn = []; // Default to empty array if there's an error
      }
    }

    if (addOn.length === 0) {
      const newItem = {
        ...item,
        count: 1, // Set default count to 1
        totalPrice: item.rate, // Set total price to item's rate (count * rate)
      };

      console.log("Adding item without add-ons: ", newItem);
      dispatch(addOrderItem(newItem));
      console.log(orderItems);
      setIsOrderSummaryVisible(true);
    } else {
      console.log("item else" + item);
      // Item has add-ons, set it as selected for further customization
      dispatch(setFoodDetails(item));
      setSelectedItem(item);
    }
  };

  const openCard = (item) => {
    dispatch(setFoodDetails(item));
    setSelectedItem(item);
  };

  const handleClose = () => {
    setSelectedItem(null);
  };

  const handleSave = (item, localOrders) => {
    console.log(item);
    console.log("localOrder" + localOrders);
    // setOrderItems([...orderItems, item]);
    setSelectedItem(null);
    if (localOrders.length > 0 || orderItems.length > 0) {
      setIsOrderSummaryVisible(true);
    }
    // setIsOrderSummaryVisible(true);
  };

  const handleCloseOrderSummary = () => {
    setIsOrderSummaryVisible(false);
  };

  function convertSnakeToText(snakeCaseText) {
    return snakeCaseText
      .split("_") // Split the string by underscores
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()) // Capitalize the first letter of each word
      .join(" "); // Join the words back with spaces
  }

  const navigate = useNavigate();

  return (
    <>
      <Box
        sx={{
          backgroundColor: "#f5f5f5",
          minHeight: "100vh",
          display: "flex",
          flexDirection: "column",
        }}
      >
        {/* Header */}
        {/* Header */}
        <Box
          sx={{
            backgroundColor: "#ffffff",
            boxShadow: "0px -2px 5px rgba(0, 0, 0, 0.4)",
            zIndex: 1000,
            paddingTop: 2,
            paddingBottom: 1,
            position: "fixed",
            top: 0,
            width: "100%",
          }}
        >
          <Grid
            container
            alignItems="center"
            sx={{
              marginBottom: "14px",
              paddingRight: "24px",
              paddingLeft: "24px",
              display: "flex",
            }}
          >
            {/* Back Button */}
            <IconButton onClick={() => navigate(-1)}>
              <Avatar
                src={backButton}
                alt="Back"
                sx={{ width: 24, height: 24 }}
              />
            </IconButton>

            {/* Title */}
            <Typography
              variant="h6"
              sx={{
                fontWeight: "bold",
                fontSize: 18,
                margin: 1,
                overflow: "hidden",
                textOverflow: "ellipsis",
                display: "-webkit-box",
                WebkitLineClamp: 2,
                WebkitBoxOrient: "vertical",
                height: "auto",
                maxHeight: "53px", // Allow two lines of text
                flexGrow: 1, // Make it grow to take available space
                textAlign: "center",
                marginRight: "8px", // To ensure space between the text and the search icon
                flexBasis: "60%", // Take 70% of the width to allow more text
              }}
            >
              {category?.name}
            </Typography>

            {/* Search Button */}
            <IconButton onClick={() => setIsSearchVisible(!isSearchVisible)}>
              <Avatar
                src={SearchImage}
                alt="Back"
                sx={{ width: 24, height: 24 }}
              />
            </IconButton>
          </Grid>

          {/* Search Field */}
          {isSearchVisible && (
            <TextField
              sx={{
                paddingRight: "24px",
                paddingLeft: "24px",
              }}
              fullWidth
              variant="outlined"
              placeholder="Search Food Items"
              value={searchQuery}
              onChange={(e) => setSearchQuery(e.target.value)}
              InputProps={{
                startAdornment: <SearchIcon sx={{ marginRight: "8px" }} />,
              }}
            />
          )}
        </Box>

        {/* Search Input */}
        {/* <Box sx={{ marginBottom: '16px' }}>
       
      </Box> */}
        <Box
          sx={{
            flexGrow: 1,
            overflow: "auto",
            marginTop: isSearchVisible ? "135px" : "85px",
            // Apply conditional margin for marginTop
            marginBottom: 0,
            // marginBottom: filteredItemsBestSeller.length > 0 ? -16 : 0,
            //  paddingRight:'24px'  ,
            paddingLeft: "24px",
          }}
        >
          {/* Bestseller Section */}
          {filteredItemsBestSeller.length > 0 && (
            <>
              <Typography
                variant="h6"
                sx={{
                  fontWeight: "bold",
                  marginBottom: "10px",
                  marginTop: "16px",
                  paddingTop: 2,
                  paddingBottom: 1,
                  textAlign: "left",
                }}
              >
                Bestseller
              </Typography>
              <Box
                sx={{
                  display: "flex",
                  overflowX: "scroll",
                  paddingBottom: "16px",
                  marginBottom: "16px",
                  minHeight: "180px", // Set a minimum height for the card
                  textAlign: "left",
                }}
              >
                {filteredItemsBestSeller.map((item, index) => {
                  const matchingItems = orderItems.filter(
                    (order) => order.id === item.id
                  );
                  console.log(matchingItems);
                  const totalCount = matchingItems.reduce(
                    (sum, currentItem) => sum + currentItem.count,
                    0
                  );
                  const useLocalImage =
                    item.icon_name && item.icon_name.includes("ic");
                  return (
                    <Box
                      onClick={() => openCard(item)} // Trigger openCard function on click
                      sx={{
                        cursor: "pointer", // Changes the cursor to pointer for better UX
                      }}
                    >
                      <Box
                        key={index}
                        sx={{
                          // minWidth: '200px',
                          backgroundColor: "#fff",
                          borderRadius: "12px",
                          padding: "16px",
                          boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)",
                          marginRight: "16px",
                          minHeight: "360px",
                          minWidth: "160px",
                          // marginBottom:20,
                        }}
                      >
                        <Box
                          sx={{
                            display: "flex",
                            justifyContent: "end",
                            margin: "-5px",
                            alignItems: "center", // To vertically align the content
                            minHeight: 18, // Ensures a consistent height
                            minWidth: 18, // Ensures a consistent width
                          }}
                        >
                          {item.item_type ? (
                            <Avatar
                              src={
                                item.item_type === "assets/icons/ic_veg.svg"
                                  ? vegIcon
                                  : item.item_type ===
                                    "assets/icons/ic_non_veg.svg"
                                  ? nonVegIcon
                                  : item.item_type ===
                                    "assets/icons/ic_non_alcoholic.svg"
                                  ? nonAlcholicIcon
                                  : item.item_type ===
                                    "assets/icons/ic_alcoholic.svg"
                                  ? alcholicIcon
                                  : null
                              }
                              variant="rounded"
                              sx={{ width: 18, height: 18 }}
                            />
                          ) : (
                            <Box sx={{ width: 18, height: 18 }} /> // Placeholder for consistent size
                          )}
                        </Box>

                        {useLocalImage ? (
                          <IconHandler
                            iconPath={item.icon_name}
                            fallbackIcon={foodIcon}
                            altText={item.name}
                            style={{
                              width: 76,
                              height: 76,
                              marginBottom: "12px",
                              margin: "0 auto",
                              marginTop: "25px",
                            }}
                          />
                        ) : (
                          <S3ImageAmplify
                            imageKey={item.icon_name}
                            grpUserId={item.user_id}
                            altText={item.name}
                            style={{
                              width: 76,
                              height: 76,
                              marginBottom: "12px",
                              margin: "0 auto",
                              marginTop: "25px",
                            }}
                          />
                        )}
                        <Box
                          sx={{
                            borderBottom: "1px solid #E5E7EB",
                            margin: "16px 0",
                          }}
                        />
                        <Typography
                          variant="body1"
                          sx={{
                            fontWeight: "bold",
                            textAlign: "left",
                            //  margin:1 ,
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                            display: "-webkit-box",
                            WebkitLineClamp: 2,
                            WebkitBoxOrient: "vertical",
                            height: "48px", // Reserve space for two lines of text
                            fontSize: 14,
                          }}
                        >
                          {item.name}
                        </Typography>
                        <Box
                          sx={{
                            borderBottom: "1px solid #E5E7EB",
                            margin: "10px 0",
                          }}
                        />
                        <Typography
                          sx={{
                            color: "#9CA3AF",
                            fontSize: "12px",
                            overflow: "hidden", // Hides overflow content
                            textOverflow: "ellipsis", // Adds ellipsis for overflowing text
                            display: "-webkit-box", // Ensures multi-line support for ellipsis
                            WebkitLineClamp: 2, // Limits the content to two lines
                            WebkitBoxOrient: "vertical", // Defines box orientation for text wrapping
                            height: "48px", // Ensures space is reserved for two lines
                            textAlign: "left", // Aligns text to the left
                            maxHeight: "35px",
                          }}
                          variant="body2"
                        >
                          {item.description || "N/A"}{" "}
                          {/* Fallback if description is null */}
                        </Typography>

                        <Box
                          sx={{
                            borderBottom: "1px solid #E5E7EB",
                            margin: "10px 0",
                          }}
                        />
                        <Box
                          sx={{
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center",
                            textAlign: "left",
                          }}
                        >
                          <Typography
                            sx={{
                              color: "blue",
                              marginTop: "8px",
                              fontWeight: "bold",
                              textTransform: "none",
                              //  paddingRight:1 ,
                              margin: 0,
                              padding: "0px 0 0 0", // Top padding of 16px (2 * 8px), and others set to 0
                            }}
                          >
                            ₹{item.rate}
                            {/* 7000 */}
                          </Typography>

                          {totalCount > 0 ? (
                            <Box sx={{ display: "block" }}>
                              <Box
                                sx={{
                                  display: "flex",
                                  alignItems: "center",
                                  // paddingLeft: 2,
                                }}
                              >
                                {/* Decrement button */}
                                <IconButton
                                  onClick={(e) => {
                                    e.stopPropagation();
                                    handleDecrement(item);
                                  }} // This function will handle decrement
                                >
                                  <Avatar
                                    src={minusButton}
                                    sx={{
                                      borderRadius: 0,
                                      width: 30,
                                      height: 30,
                                    }}
                                  />
                                </IconButton>

                                {/* Count */}
                                <Typography sx={{ fontWeight: "bold" }}>
                                  {totalCount}
                                </Typography>

                                {/* Increment button */}
                                <IconButton
                                  onClick={(e) => {
                                    e.stopPropagation();
                                    handleIncrement(item);
                                  }} // This function will handle increment
                                >
                                  <Avatar
                                    src={plusButton}
                                    sx={{
                                      borderRadius: 0,
                                      width: 30,
                                      height: 30,
                                    }}
                                  />
                                </IconButton>
                              </Box>
                            </Box>
                          ) : (
                            <Box sx={{ display: "block" }}>
                              <Button
                                variant="outlined"
                                sx={{
                                  borderRadius: "16px",
                                  padding: "2px 4px",
                                  textTransform: "none",
                                  fontWeight: "bold",
                                  color: "#4caf50",
                                  borderColor: "#4caf50",
                                  marginTop: "6px",
                                  alignSelf: "center",
                                }}
                                onClick={(e) => {
                                  e.stopPropagation(); // Prevent triggering openCard
                                  handleAddToOrder(item);
                                }}
                                // onClick={() => handleAddToOrder(item)} // This function will handle adding the item
                              >
                                Add
                              </Button>
                            </Box>
                          )}
                        </Box>
                        {item.add_on_checklist !== "[]" &&
                          item.add_on_checklist && (
                            <Typography
                              sx={{
                                fontSize: "12px",
                                textAlign: "right",
                                paadding: 1,
                                paddingTop: "5px",
                              }}
                            >
                              Customisable
                            </Typography>
                          )}
                      </Box>
                    </Box>
                  );
                })}
              </Box>
            </>
          )}
        </Box>

        {/* Menu Section */}
        <Box
          sx={{
            flexGrow: 1,
            overflow: "auto",
            marginTop: isSearchVisible
              ? "50px" // When search is visible
              : filteredItemsBestSeller.length > 0
              ? "5px" // When there are bestseller items but search is not visible
              : "28px", // Default case
            marginBottom: isOrderSummaryVisible ? "120px" : "55px",

            paddingRight: "24px",
            paddingLeft: "24px",
            //  marginLeft:'-2px',
          }}
        >
          <Typography
            variant="h6"
            sx={{
              fontWeight: "bold",
              marginBottom: "12px",
              marginTop: "0px",
              textAlign: "left",
            }}
          >
            Menu
          </Typography>

          {filteredItems.length === 0 &&
          filteredItemsBestSeller.length === 0 ? (
            <RenderNoResults />
          ) : (
            <Grid container spacing={2}>
              {filteredItems.map((item, index) => {
                const matchingItems = orderItems.filter(
                  (order) => order.id === item.id
                );
                const totalCount = matchingItems.reduce(
                  (sum, currentItem) => sum + currentItem.count,
                  0
                );
                // Check if the icon path includes 'ic' for using the local image
                const useLocalImage =
                  item.icon_name && item.icon_name.includes("ic");

                return (
                  <Grid item xs={6} key={index}>
                    <Box
                      onClick={() => openCard(item)} // Trigger openCard function on click
                      sx={{
                        cursor: "pointer", // Changes the cursor to pointer for better UX
                      }}
                    >
                      <Box
                        sx={
                          item.status === "recommended_by_hosts"
                            ? {
                                // width:40 , height:66,
                                //   marginBottom:'-51px'
                                //   , display:'flex' , justifyContent:'start'
                              }
                            : {
                                width: 40,
                                height: 62,
                                marginBottom: "-51px",
                                display: "flex",
                                justifyContent: "start",
                              }
                        }
                      >
                        {item.status &&
                          item.status !== "no_status" &&
                          item.status !== "null" && (
                            <div
                              className="deal-banner"
                              style={
                                item.status === "recommended_by_host"
                                  ? {
                                      //  whiteSpace: 'nowrap'
                                      // padding: '8px , 12px',
                                    }
                                  : {
                                      whiteSpace: "nowrap",
                                      // padding: '12px , 12px',
                                    }
                              }
                            >
                              <span
                                sx={{
                                  width: 10,
                                }}
                              >
                                {/* {truncateText(item.status || "TODAY'S DEAL", 9)} */}
                                {convertSnakeToText(
                                  // "recommended_by_host"
                                  item.status
                                )}
                              </span>
                            </div>
                          )}
                      </Box>
                      <Box
                        sx={{
                          backgroundColor: "#fff",
                          borderRadius: "12px",
                          padding: "16px",
                          boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)",
                          // textAlign: 'center',
                          minHeight: "360px",
                          minWidth: "150px",
                          display: "flex",
                          flexDirection: "column",
                          justifyContent: "space-between", // Ensures button is at the bottom,
                          textAlign: "left",
                          // margin : 1,
                          // marginRight:'2px',
                          marginLeft: "-2px",
                        }}
                      >
                        <Box>
                          <Box
                            sx={{
                              display: "flex",
                              justifyContent: "end",
                              alignItems: "center", // To vertically align the content
                              minHeight: 18, // Ensures a consistent height
                              minWidth: 18, // Ensures a consistent width
                            }}
                          >
                            {item.item_type ? (
                              <Avatar
                                src={
                                  item.item_type === "assets/icons/ic_veg.svg"
                                    ? vegIcon
                                    : item.item_type ===
                                      "assets/icons/ic_non_veg.svg"
                                    ? nonVegIcon
                                    : item.item_type ===
                                      "assets/icons/ic_non_alcoholic.svg"
                                    ? nonAlcholicIcon
                                    : item.item_type ===
                                      "assets/icons/ic_alcoholic.svg"
                                    ? alcholicIcon
                                    : null
                                }
                                variant="rounded"
                                sx={{ width: 18, height: 18 }}
                              />
                            ) : (
                              <Box sx={{ width: 18, height: 18 }} /> // Placeholder for consistent size
                            )}
                          </Box>

                          {useLocalImage ? (
                            <IconHandler
                              iconPath={item.icon_name}
                              fallbackIcon={foodIcon}
                              altText={item.name}
                              style={{
                                width: 76,
                                height: 76,
                                marginBottom: "12px",
                                margin: "0 auto",
                                marginTop: "25px",
                              }}
                            />
                          ) : (
                            <S3ImageAmplify
                              imageKey={item.icon_name}
                              grpUserId={item.user_id}
                              altText={item.name}
                              style={{
                                width: 76,
                                height: 76,
                                marginBottom: "12px",
                                margin: "0 auto",
                                marginTop: "25px",
                              }}
                            />
                          )}

                          <Box
                            sx={{
                              borderBottom: "1px solid #E5E7EB",
                              margin: "12px 0",
                            }}
                          />
                          <Typography
                            variant="body1"
                            sx={{
                              //  fontWeight: 'bold', margin: 1, whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis'
                              fontWeight: "bold",
                              // margin: 1,
                              overflow: "hidden",
                              textOverflow: "ellipsis",
                              display: "-webkit-box",
                              WebkitLineClamp: 2,
                              WebkitBoxOrient: "vertical",
                              height: "48px", // Reserve space for two lines of text
                              textAlign: "left",
                              fontSize: 14,
                              marginBottom: 0.5,
                              marginTop: 1,
                              // paddingRight:'-10px',
                              margin: 0,
                            }}
                          >
                            {item.name}
                          </Typography>
                          <Box
                            sx={{
                              borderBottom: "1px solid #E5E7EB",
                              margin: "10px 0",
                            }}
                          />
                          <Typography
                            sx={{
                              color: "#9CA3AF",
                              fontSize: "12px",
                              overflow: "hidden", // Hides overflow content
                              textOverflow: "ellipsis", // Adds ellipsis for overflowing text
                              display: "-webkit-box", // Ensures multi-line support for ellipsis
                              WebkitLineClamp: 2, // Limits the content to two lines
                              WebkitBoxOrient: "vertical", // Defines box orientation for text wrapping
                              height: "48px", // Ensures space is reserved for two lines
                              textAlign: "left", // Aligns text to the left
                              maxHeight: "35px",
                            }}
                            variant="body2"
                          >
                            {item.description || "N/A"}{" "}
                            {/* Fallback if description is null */}
                          </Typography>

                          <Box
                            sx={{
                              borderBottom: "1px solid #E5E7EB",
                              margin: "5px 0",
                            }}
                          />
                          <Box
                            sx={{
                              display: "flex",
                              justifyContent: "space-between",
                              alignItems: "center",
                              textAlign: "left",
                            }}
                          >
                            <Typography
                              sx={{
                                color: "blue",
                                marginTop: "8px",
                                fontWeight: "bold",
                                textTransform: "none",
                                //  paddingLeft:1 ,
                                margin: 0,
                                // paddingTop: 1,
                                padding: "0px 0 0 0",
                              }}
                            >
                              ₹{item.rate}
                              {/* 7000 */}
                            </Typography>

                            {totalCount > 0 ? (
                              <Box sx={{ display: "block" }}>
                                <Box
                                  sx={{ display: "flex", alignItems: "center" }}
                                >
                                  {/* Decrement button */}
                                  <IconButton
                                    // onClick={() => handleDecrement(item)}
                                    onClick={(e) => {
                                      e.stopPropagation(); // Prevent triggering openCard
                                      handleDecrement(item);
                                    }}
                                    size="small"
                                  >
                                    <Avatar
                                      src={minusButton}
                                      sx={{
                                        borderRadius: 0,
                                        width: 30,
                                        height: 30,
                                      }}
                                    />
                                  </IconButton>
                                  {/* Count */}
                                  <Typography sx={{ fontWeight: "bold" }}>
                                    {totalCount}
                                  </Typography>

                                  {/* Increment button */}
                                  <IconButton
                                    // onClick={() => handleIncrement(item)}
                                    onClick={(e) => {
                                      e.stopPropagation(); // Prevent triggering openCard
                                      handleIncrement(item);
                                    }}
                                    size="small"
                                  >
                                    <Avatar
                                      src={plusButton}
                                      sx={{
                                        borderRadius: 0,
                                        width: 30,
                                        height: 30,
                                      }}
                                    />
                                  </IconButton>
                                </Box>
                            
                              </Box>
                            ) : (
                              <Box sx={{ display: "block" }}>
                                <Button
                                  variant="outlined"
                                  sx={{
                                    borderRadius: "24px",
                                    padding: "4px 8px",
                                    textTransform: "none",
                                    fontWeight: "bold",
                                    color: "#4caf50",
                                    borderColor: "#4caf50",
                                    marginTop: "6px",
                                    alignSelf: "center",
                                  }}
                                  onClick={(e) => {
                                    e.stopPropagation(); // Prevent triggering openCard
                                    handleAddToOrder(item);
                                  }}
                                  // onClick={() => handleAddToOrder(item)} // This function will handle adding the item
                                >
                                  Add
                                </Button>
                              </Box>
                            )}
                          </Box>
                          {item.add_on_checklist !== "[]" &&
                            item.add_on_checklist && (
                              <Typography
                                sx={{
                                  fontSize: "12px",
                                  textAlign: "right",
                                  paadding: 1,
                                  paddingTop: "5px",
                                }}
                              >
                                Customisable
                              </Typography>
                            )}
                        </Box>
                      </Box>
                    </Box>
                  </Grid>
                );
              })}
            </Grid>
          )}
        </Box>

        {/* Food Detail Modal */}
        {selectedItem && (
          <FoodDetailModal
            open={Boolean(selectedItem)}
            onClose={handleClose}
            onCloseOrderSummary={handleCloseOrderSummary}
            onSave={handleSave}
            item={selectedItem}
          />
        )}
      </Box>
      {isOrderSummaryVisible && (
        <Box
          sx={{
            position: "fixed",
            bottom: 0,
            left: 0,
            right: 0,
            backgroundColor: "#fff",
            boxShadow: "0px -4px 10px rgba(0, 0, 0, 0.1)",
            // padding: '16px',
            borderTop: "1px solid #e0e0e0",
            zIndex: 1000,
            marginTop: "auto",
            margin: 0,
          }}
        >
          <OrderSummary
            orderItems={orderItems}
            onClose={handleCloseOrderSummary}
            icon={icon}
          />
        </Box>
      )}
    </>
  );
};

export default FoodItemList;
