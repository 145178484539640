import React from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Typography,
  Box,
} from "@mui/material";
import Logo from "../images/icons/logo.svg";

const OHSM = () => {
  const [scanError, setScanError] = React.useState(false);

  const handleRetry = () => {
    setScanError(false);
    // setIsScanning(true);
  };

  return (
    <Dialog
      open={true}
      disableEscapeKeyDown
      disableBackdropClick
      PaperProps={{
        style: {
          borderRadius: 20,
          padding: "20px",
          width: "90%",
          maxWidth: "400px",
        },
      }}
    >
      <DialogTitle
        sx={{ textAlign: "center", fontWeight: "bold", color: "#E7319DFF" }}
      >
        OHSM POS
      </DialogTitle>
      <Box sx={{ borderBottom: "1px solid #E5E7EB", margin: "3px 0" }} />
      <DialogContent>
        <Typography
          variant="subtitle1"
          sx={{ textAlign: "center", marginBottom: "16px", fontWeight: "bold" }}
        >
          Please Scan QR Code or Ask property to share the link
        </Typography>
      </DialogContent>
      <DialogActions sx={{ justifyContent: "center", paddingTop: 0.5 }}>
        {scanError && (
          <Button
            variant="outlined"
            fullWidth
            onClick={handleRetry}
            sx={{ color: "#2FA551", fontWeight: "bold" }}
          >
            Retry
          </Button>
        )}
      </DialogActions>
      <Typography
        variant="caption"
        sx={{
          display: "flex",
          alignItems: "end",
          justifyContent: "end",
          marginTop: "16px",
          color: "#9E9E9E",
        }}
      >
        Powered By: <img alt="logo" style={{ marginLeft: 4 }} src={Logo} />
      </Typography>
    </Dialog>
  );
};

export default OHSM;
