import React, { useState , useCallback } from 'react';
import { AppBar, Box, Button, Typography, Avatar, Grid, IconButton, Dialog  } from '@mui/material';
import expIcon from "../../images/icons/exp_icon.svg";
import fnbIcon from "../../images/icons/fnb_icon.svg";
import { useNavigate , useLocation, useParams } from 'react-router-dom';
import { Logout as LogoutIcon, History as HistoryIcon, } from '@mui/icons-material';
import Login from '../Login';
import { navigateWithHostelParams } from '../../components/NavigateWithHostelParams';
import Swal from 'sweetalert2';
import { CategoryButton } from './CategoryButton';





const Menu = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const token = localStorage.getItem('TOKEN');
  const [isLoginDialogOpen, setIsLoginDialogOpen] = useState(false);
  const { hostel_name, hostel_id } = useParams();
  const [hostelName , setHostelName] = useState(
    localStorage.getItem('hostel_name')
);


  const storedHostelName = localStorage.getItem('hostel_name');
  const storedHostelId = localStorage.getItem('hostel_id');

  //  // If this is the user's first visit, set the hostel details in localStorage
  //  if (!storedHostelName && !storedHostelId) {
  //   localStorage.setItem('hostel_id', hostel_id);
  //   localStorage.setItem('hostel_name', hostel_name);
  //   return; // Exit the function as there's no mismatch to check
  // }
 
  // // const { HostelId } = useParams();

  //  // Check if hostel name or hostel ID mismatches
  //    if (storedHostelName !== hostel_name || storedHostelId !== hostel_id) {
  //     console.log('Hostel name or hostel ID mismatch');
  //     localStorage.clear();
  //     Swal.fire(
  //       'You are jumping into new OHSM property',
  //     )
  //     // return <Navigate to={`${hostelPath}/food-menu`} replace />;
  //     // retur
  //   }


  // Handle the initial setup or mismatch
  React.useEffect(() => {
    if (!storedHostelName && !storedHostelId) {
      localStorage.setItem('hostel_id', hostel_id);
      localStorage.setItem('hostel_name', hostel_name);
    } else if (storedHostelName !== hostel_name || storedHostelId !== hostel_id) {
      console.log('Hostel name or hostel ID mismatch');
      localStorage.clear();
      Swal.fire({
        title: 'Property Change Detected',
        text: 'You are jumping into a new OHSM property. Refreshing the page...',
        icon: 'info',
        confirmButtonText: 'OK',
      }).then(() => {
        // Reset localStorage to the new hostel details
        localStorage.setItem('hostel_id', hostel_id);
        localStorage.setItem('hostel_name', hostel_name);
  
        // Refresh the page to ensure the app reflects the new property
        window.location.reload();
      });
    }
  }, [hostel_name, hostel_id, storedHostelId, storedHostelName]);
     
      
 
    // localStorage.setItem("hostel_id", hostel_id);
    // localStorage.setItem("hostel_name", hostel_name);

  const handleLoginSuccess = () => {
    setIsLoginDialogOpen(false);
    console.log("User logged in successfully!");
    // Proceed with order placement or any post-login actions
  };



  // Enhanced logout handler
  const handleLogout = useCallback((isSessionExpired = false) => {
    try {
      // Store hostel info before clearing
      const hostelId = localStorage.getItem('hostel_id');
      const hostelName = localStorage.getItem('hostel_name');
     

      // Clear all session and user-specific data
      const itemsToKeep = ['hostel_id', 'hostel_name'];
      Object.keys(localStorage).forEach(key => {
        if (!itemsToKeep.includes(key)) {
          localStorage.removeItem(key);
        }
      });

      // Clear any Redux state if needed
      // dispatch(clearUserData()); // Add your Redux clear action if needed

      // Force a state update
      window.dispatchEvent(new Event('storage'));

      // Navigate to login with preserved hostel parameters
      navigateWithHostelParams(navigate, '/food-menu', {
        hostel_id: hostelId,
        hostel_name: hostelName,
        state: {
          loggedOut: true,
          sessionExpired: Boolean(isSessionExpired)
        },
        replace: true // Important: prevent back navigation
      });
       // Refresh the page after fallback navigation
    window.location.reload();

    } catch (error) {
      console.error('Logout error:', error);
      // Fallback navigation
      const hostelId = localStorage.getItem('hostel_id');
      const hostelName = localStorage.getItem('hostel_name');
      navigate(`/hostel_name/${hostelName}/hostel_id/${hostelId}/login`, { 
        replace: true,
        state: { error: true }
      });
       // Refresh the page after fallback navigation
    window.location.reload();
    }
  }, [navigate]);

 

 


 

  // Periodic session check


  const handleOrderHistory = useCallback(() => {
    const token = localStorage.getItem("TOKEN");
    if (!token) {
      setIsLoginDialogOpen(true); // Open login dialog if user is not authenticated
    } else {
    navigateWithHostelParams(navigate, '/order-history', {
      state: { fromMenu: true }
    });
  }
  }, [navigate]);


 

  // Usage in your app:
  
  return (
    <>
    <Box sx={{ padding: '24px', backgroundColor: '#f5f5f5', height: '100vh' }}>
      {/* <AppBar position="static" color="transparent" elevation={0}>
        <Grid container justifyContent="center" alignItems="center">
          <Grid item>
            <Typography variant="h6" sx={{ fontWeight:'bold'}}>
             {hostel_name}
            </Typography>
          </Grid>
        </Grid>
      </AppBar> */}
       <AppBar position="static" color="transparent" elevation={0}>
      <Grid container alignItems="center">
        {/* Top Left - Order History Button */}
        <Grid item xs={4} display="flex" justifyContent="flex-start">
          <Button
            startIcon={<HistoryIcon />}
            onClick={handleOrderHistory}
      
            sx={{ fontWeight: 'bold', color: 'primary.main' }}
          >
            Order History
          </Button>
        </Grid>

        {/* Center - Hostel Name */}
        <Grid item xs={4} display="flex" justifyContent="center">
          <Typography variant="h6" sx={{ fontWeight: 'bold' }}>
            {hostelName ||  hostel_name} 
          </Typography>
        </Grid>

        {/* Top Right - Logout Button */}
        {
         token && (
          <>
             <Grid item xs={4} display="flex" justifyContent="flex-end">
          <IconButton onClick={handleLogout} color="primary">
            <LogoutIcon />
          </IconButton>
        </Grid>
          </>
         )
        }
     
      </Grid>
    </AppBar>
      <Box sx={{ borderBottom: '1px solid #E5E7EB', margin: '16px 0' }} />
      <Box sx={{ textAlign: 'left', marginTop: 3 }}>
        <Typography sx={{  fontWeight: 'bold' }}>
          Dear Guest,
        </Typography>
        <Typography variant='subtitle1' sx={{  fontWeight: 500 , fontSize:14 }}>
          Explore our F&B and Experiences & Merchandise menus!
        </Typography>
      </Box>
      <Button
        variant="outlined"
        sx={{
          borderRadius: '24px',
          padding: '4px 16px',
          textTransform: 'none',
          fontWeight: 'bold',
          color: '#d63384',
          borderColor: '#d63384',
          marginBottom: '16px',
          marginTop: '16px',
        }}
      >
        Menu
      </Button>
      <CategoryButton
        icon={<Avatar src={fnbIcon} variant="rounded" />}
        parentSubCat="fnb" // Pass 'fnb' as the parent_sub_cat
        label="Food & Beverage"
      />
      <CategoryButton
        icon={<Avatar src={expIcon} variant="rounded" />}
        parentSubCat="exp" // Pass 'exp' as the parent_sub_cat
        label="Experiences & Merchandise"
      />
    </Box>
  
       
    <Dialog open={isLoginDialogOpen} onClose={() => setIsLoginDialogOpen(false)}
  aria-labelledby="login-dialog-title"
     >
        <Login onLoginSuccess={handleLoginSuccess} onClose={() => setIsLoginDialogOpen(false)} />
      </Dialog>
    </>
  );
};

export default Menu;
