import {
  Box,
  Dialog,
  Grid,
  IconButton,
  TextField,
  DialogContent,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  CircularProgress,
  Paper,
  Typography,
  Avatar,
} from "@mui/material";
import { useState } from "react";
import SearchIcon from "@mui/icons-material/Search";
import CloseIcon from "@mui/icons-material/Close";
import IconHandler from "../../../components/IconHandler";
import S3ImageAmplify from "../../../config/S3ImageAmplify";
import foodIcon from "../../../images/icons/food.svg";

export const SearchDialog = ({
  items,
  categories,
  open,
  onClose,
  setIsSearchDialogOpen,
  loading,
  handleCategoryClick,
  handleCategoryClickByItem,
  categoryIcons,
  searchResults,
  searchLoading,
  originalLabel,
}) => {
  const [searchQuery, setSearchQuery] = useState("");

  const filteredItemsIn = items?.filter((item) =>
    item.name.toLowerCase().includes(searchQuery.toLowerCase())
  );

  const filteredcategorysIn = categories?.filter((category) =>
    category.name.toLowerCase().includes(searchQuery.toLowerCase())
  );

  

  return (
    <Dialog
      fullScreen
      open={open}
      onClose={onClose}
      PaperProps={{
        style: {
          backgroundColor: "#f5f5f5",
        },
      }}
    >
      <Box
        sx={{
          backgroundColor: "#fff",
          padding: 2,
          position: "sticky",
          top: 0,
          zIndex: 1,
        }}
      >
        <Grid container spacing={2} alignItems="center">
          <Grid item>
            <IconButton onClick={() => setIsSearchDialogOpen(false)}>
              <CloseIcon />
            </IconButton>
          </Grid>
          <Grid item xs>
            <TextField
              fullWidth
              variant="outlined"
              placeholder="Search categories and items..."
              value={searchQuery}
              onChange={(e) => setSearchQuery(e.target.value)}
              InputProps={{
                startAdornment: <SearchIcon sx={{ color: "gray", mr: 1 }} />,
              }}
              autoFocus
            />
          </Grid>
        </Grid>
      </Box>

      <DialogContent sx={{ padding: 2 }}>
        {loading ? (
          <Box display="flex" justifyContent="center" padding={4}>
            <CircularProgress />
          </Box>
        ) : (
          <>
            {filteredcategorysIn?.length > 0 && (
              <>
                <Typography variant="h6" sx={{ mb: 2, fontWeight: "bold" }}>
                  Categories
                </Typography>
                <List>
                  {filteredcategorysIn?.map((category) => (
                    <Paper
                      key={category.id}
                      elevation={1}
                      sx={{ mb: 2, borderRadius: 2 }}
                    >
                      <ListItem
                        button
                        onClick={() => handleCategoryClick(category)}
                      >
                        <ListItemAvatar>
                          <Avatar
                            src={categoryIcons[originalLabel]}
                            variant="rounded"
                          />
                        </ListItemAvatar>
                        <ListItemText
                          primary={category.name}
                          secondary={`${category.total_inventory} items`}
                        />
                      </ListItem>
                    </Paper>
                  ))}
                </List>
              </>
            )}

            {filteredItemsIn?.length > 0 && (
              <>
                <Typography
                  variant="h6"
                  sx={{ mb: 2, mt: 3, fontWeight: "bold" }}
                >
                  Items
                </Typography>
                <List>
                  {filteredItemsIn?.map((item) => {
                    const useLocalImage =
                      item.icon_name && item.icon_name.includes("ic");
                    return (
                      <Paper
                        key={item.id}
                        elevation={1}
                        sx={{ mb: 2, borderRadius: 2, cursor: "pointer" }}
                        onClick={() => handleCategoryClickByItem(item)} // Call handleCategoryClick with item data
                      >
                        <ListItem>
                          <ListItemAvatar>
                            {useLocalImage ? (
                              <IconHandler
                                iconPath={item.icon_name}
                                fallbackIcon={foodIcon}
                                altText={item.name}
                              />
                            ) : (
                              <S3ImageAmplify
                                imageKey={item.icon_name}
                                grpUserId={item.user_id}
                                altText={item.name}
                              />
                            )}
                          </ListItemAvatar>
                          <ListItemText
                            primary={item.name}
                            secondary={
                              <>
                                <Typography component="span" variant="body2">
                                  {item.description}
                                </Typography>
                                <br />
                                <Typography
                                  component="span"
                                  variant="body2"
                                  color="primary"
                                >
                                  ₹{item.rate}
                                </Typography>
                              </>
                            }
                          />
                        </ListItem>
                      </Paper>
                    );
                  })}
                </List>
              </>
            )}

            {searchQuery &&
              !searchLoading &&
              searchResults.categories.length === 0 &&
              searchResults.items.length === 0 && (
                <Box sx={{ textAlign: "center", py: 4 }}>
                  <Typography color="text.secondary">
                    No results found for "{searchQuery}"
                  </Typography>
                </Box>
              )}
          </>
        )}
      </DialogContent>
    </Dialog>
  );
};
