import React, { createContext, useState, useContext } from 'react';

const HostelContext = createContext();

export const HostelProvider = ({ children }) => {
  const [hostelId, setHostelId] = useState(localStorage.getItem('hostel_id') || '');
  const [hostelName, setHostelName] = useState(localStorage.getItem('hostel_name') || '');
  const [categoryId, setCategoryId] = useState(localStorage.getItem('categoryId') || '');
  const [userId, setUserId] = useState(localStorage.getItem('user_id') || '');

  const updateHostelId = (id) => {
    setHostelId(id);
    localStorage.setItem('hostel_id', id);
  };

  const updateUserId = (id) => {
    setUserId(id);
    localStorage.setItem('user_id', id);
  };

  const updateHostelName = (name) => {
    setHostelName(name);
    localStorage.setItem('hostel_name', name);
  };

  const updateCategoryId = (id) => {
    setCategoryId(id);
    localStorage.setItem('categoryId', id);
  };

  return (
    <HostelContext.Provider
      value={{
        hostelId,
        hostelName,
        categoryId,
        userId,
        updateHostelId,
        updateHostelName,
        updateCategoryId,
        updateUserId,
      }}
    >
      {children}
    </HostelContext.Provider>
  );
};

export const useHostelContext = () => useContext(HostelContext);
