import { useEffect, useState } from 'react';
import { IconButton ,Avatar , Box, Typography , AppBar, Grid } from '@mui/material';
import OrderCard from '../components/OrderCard';
import { getHostelData } from '../api/services/FetchNodeService';
import { useNavigate } from 'react-router-dom';
import backButton from "../images/icons/backIcon.svg";
import FoodLoader from "../Loader/loading";
import { useGuestContext } from '../context/GuestContext';

const OrderHistory = () => {
  const navigate = useNavigate();
  const [orders, setOrders] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const { guestName, guestPhone } = useGuestContext(); // Access guest info from context

  useEffect(() => {
    const fetchOrderHistory = async () => {
      setLoading(true);
      setError(null);
      try {
        const id = localStorage.getItem('hostel_id');
        const phone = localStorage.getItem('guestPhone');
        const response = await getHostelData(`order-history-foodMenu-hostel/${id}/${phone}`);
        
      // Sort orders by date (latest first) if there's a date property
      const sortedOrders = response.data.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt));
      setOrders(sortedOrders);
      console.log(sortedOrders);
      } catch (err) {
        setError('Failed to load order history');
      } finally {
        setLoading(false);
      }
    };

    fetchOrderHistory();
  }, []);

  if (loading) {
    return (
      <FoodLoader loading={loading} />
    );
  }

  if (error) {
    return (
      <>
          <AppBar position="static" color="transparent" elevation={0} sx={{ padding: 1 }}>
      <Grid container alignItems="center" sx={{ padding: 1 }}>
        {/* Back Button on the Left */}
        <Grid item xs={2}>
          <IconButton onClick={() => navigate(-1)}>
            <Avatar
              src={backButton} // Make sure to import backButton correctly
              alt="Back"
              sx={{ width: 24, height: 24 }}
            />
          </IconButton>
        </Grid>

        {/* Title Centered */}
        <Grid item xs={8} container justifyContent="center">
          <Typography variant="h6" sx={{ fontWeight: 'bold' }}>
            Order History
          </Typography>
        </Grid>

        {/* Optional empty space or button on the right side */}
        <Grid item xs={2} />
      </Grid>
    </AppBar>
      <Box display="flex" justifyContent="center" mt={4}>
        <Typography color="error">{error}</Typography>
      </Box>
      </>
    );
  }

  if (orders.length === 0) {
    return (
      <>
         <AppBar position="static" color="transparent" elevation={0} sx={{ padding: 1 }}>
      <Grid container alignItems="center" sx={{ padding: 1 }}>
        {/* Back Button on the Left */}
        <Grid item xs={2}>
          <IconButton onClick={() => navigate(-1)}>
            <Avatar
              src={backButton} // Make sure to import backButton correctly
              alt="Back"
              sx={{ width: 24, height: 24 }}
            />
          </IconButton>
        </Grid>

        {/* Title Centered */}
        <Grid item xs={8} container justifyContent="center">
          <Typography variant="h6" sx={{ fontWeight: 'bold' }}>
            Order History
          </Typography>
        </Grid>

        {/* Optional empty space or button on the right side */}
        <Grid item xs={2} />
      </Grid>
    </AppBar>
      <Box display="flex" justifyContent="center" mt={4}>
        <Typography>No order history found.</Typography>
      </Box>
      </>
    );
  }

  return (
    <Box sx={{ padding: '10px', backgroundColor: '#f5f5f5', height: 'auto'  }}>
      <AppBar position="static" color="transparent" elevation={0} sx={{ padding: 1 }}>
      <Grid container alignItems="center" sx={{ padding: 1 }}>
        {/* Back Button on the Left */}
        <Grid item xs={2}>
          <IconButton onClick={() => navigate(-1)}>
            <Avatar
              src={backButton} // Make sure to import backButton correctly
              alt="Back"
              sx={{ width: 24, height: 24 }}
            />
          </IconButton>
        </Grid>

        {/* Title Centered */}
        <Grid item xs={8} container justifyContent="center">
          <Typography variant="h6" sx={{ fontWeight: 'bold' }}>
            Order History
          </Typography>
        </Grid>

        {/* Optional empty space or button on the right side */}
        <Grid item xs={2} />
      </Grid>
    </AppBar>
      <Box sx={{ borderBottom: '1px solid #E5E7EB', margin: '16px 16px' }} />
      <Box sx={{ padding: '10px' }}>
      {orders.map((order) => (
        <Box key={order.id} mb={2} mt={2}>
          <OrderCard order={order} guest={guestName} phone={guestPhone} onServe={() => { /* Define any serve logic if needed */ }} />
        </Box>
      ))}
      </Box>
    </Box>
  );
};

export default OrderHistory;
