import React, { useEffect, useState } from "react";
import {
  Box,
  Typography,
  Grid,
  Avatar,
  Button,
  IconButton,
  FormControlLabel,
  Modal,
  TextField,
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
import backButton from "../images/icons/backIcon.svg";
import { useNavigate } from "react-router-dom";
import { Check, CheckBox } from "@mui/icons-material";
import vegIcon from "../images/icons/vegIcon.svg";
import OrderSummary from "../components/OrderSummary";
import { useLocation } from "react-router-dom";
import "./DealBanner.css"; // Assuming you are importing CSS from an external file
import { getHostelData } from "../api/services/FetchNodeService";
import foodIcon from "../images/icons/food.svg";
import baverageIcon from "../images/icons/baverage.svg";
// import nonVegIcon from "../images/icons/nonVegIcon.svg";
import nonVegIcon from "../images/icons/ic_non_veg.svg";
// import nonAlcholicIcon from "../images/icons/nonAlcoholicIcon.svg";
import nonAlcholicIcon from "../images/icons/ic_non_alcoholic.svg";
// import alcholicIcon from "../images/icons/AlcoholicIcon.svg";
import alcholicIcon from "../images/icons/ic_alcoholic.svg";
import trackingIcon from "../images/icons/trackingIcon.svg";
import SearchImage from "../images/icons/search.svg";
import plusButton from "../images/icons/plus.svg";
import minusButton from "../images/icons/minus.svg";
import { useDispatch, useSelector } from "react-redux";
import {
  setFoodDetails,
  addOrderItem,
  updateOrderItemCount,
  removeOrderItem,
} from "../Redux/store";
import S3ImageAmplify from "../config/S3ImageAmplify";
import IconHandler from "./IconHandler";

export const FoodDetailModal = ({ open, onClose, onCloseOrderSummary , item, onSave }) => {
  const dispatch = useDispatch();
  const [count, setCount] = useState(0);
  const [icon , setIcons] = useState(foodIcon);
  const [localOrders, setLocalOrders] = useState([]);
  const foodDetails = useSelector((state) => state.food.foodDetails);
  console.log(foodDetails);
  const orders = useSelector((state) => state.food.orderItems);

  const [selectedAddOns, setSelectedAddOns] = useState([]);
  const [showMore, setShowMore] = useState(false); // State to handle collapse
  const [itemRate, setItemRate] = useState(item.rate);

  const addOnChecklist = foodDetails.add_on_checklist
    ? JSON.parse(foodDetails.add_on_checklist)
    : [];

  useEffect(() => {
    // Initialize local orders with existing orders for this item
    const existingOrders = orders.filter((order) => order.id === item.id);
    setLocalOrders(existingOrders);

    if (foodDetails && foodDetails.sub_category_id && foodDetails.sub_category_id) {
      updateIcon(foodDetails.sub_category_id , foodDetails.sub_category_id );
    }

  }, [orders, foodDetails.id , foodDetails]);

  // useEffect(() => {
  //   if (foodDetails && foodDetails.sub_category_id && foodDetails.sub_category_id) {
  //     updateIcon(foodDetails.sub_category_id , foodDetails.sub_category_id );
  //   }
  // }, [foodDetails]);


  function updateIcon(categoryName , categoryParentSubCategory) {
    const isBeverage = categoryName.toLowerCase().includes('beverage');
    const isExp = categoryParentSubCategory.toLowerCase().includes('exp');
    setIcons(isBeverage ? baverageIcon
      : isExp ? trackingIcon 
      : foodIcon
    );
  }

  const matchingItems = localOrders.filter((order) => order.id === item.id);
  console.log(matchingItems);
  const totalCount = matchingItems.reduce(
    (sum, currentItem) => sum + currentItem.count,
    0
  );

  if (!foodDetails) return null;


  const handleAddClick = () => {
    // Iterate through localOrders to update the Redux store
    console.log(localOrders);    
    localOrders.forEach((order) => {
      // Check if the order count is greater than 0
      if (order.count > 0) {
        // Check if the order already exists in Redux orders
        const existingOrderIndex = orders.findIndex(
          (existingOrder) =>
            existingOrder.id === order.id &&
            JSON.stringify(existingOrder.addOns) ===
              JSON.stringify(order.addOns)
        );

        if (existingOrderIndex > -1) {
          // If the order exists, update its count and total price
          dispatch(
            updateOrderItemCount({
              ...orders[existingOrderIndex],
              count:  order.count, // Increment the count
              totalPrice:
                 order.totalPrice, // Update the total price
            })
          );
        } else {
          // If the order does not exist, add it to the Redux store
          dispatch(addOrderItem(order));
        }
      } else {
        // If count is 0, remove the order from Redux store
        const existingOrderIndex = orders.findIndex(
          (existingOrder) =>
            existingOrder.id === order.id &&
            JSON.stringify(existingOrder.addOns) ===
              JSON.stringify(order.addOns)
        );
        console.log(existingOrderIndex);
        if (existingOrderIndex > -1) {
          console.log(existingOrderIndex);
          dispatch(removeOrderItem(orders[existingOrderIndex])); // Remove the order from Redux store
        }
      }
     
    });
    console.log("orders", orders);
    // Save localOrders and selected add-ons
    onSave(item, localOrders, selectedAddOns);
    onClose(); // Close the modal
  };

  const handleIncrement = () => {
    // Calculate the item rate
    const addOnTotal = selectedAddOns.reduce((acc, addon) => {
      return acc + (addon.rate || 0); // Use 0 if price is undefined
    }, 0);
    console.log(addOnTotal);

    const newItemRate = (foodDetails.rate + addOnTotal) * count ; // Calculate new item rate
  
    setItemRate(newItemRate);

    const newCount = 1; // Increment count
    setCount(newCount);

  
    console.log(localOrders);

    // Check if the item already exists in localOrders
    setLocalOrders((prevOrders) => {
      const existingItemIndex = prevOrders.findIndex(
        (orderItem) =>
          orderItem.id === item.id &&
          JSON.stringify(orderItem.addOns) === JSON.stringify(selectedAddOns)
      );

      if (existingItemIndex > -1) {
        console.log("old Item");
        // If the item exists, update its count and total price
        const updatedOrders = [...prevOrders];
         const existingOrder = updatedOrders[existingItemIndex];
         console.log(existingOrder);
      
         const newCount = 1; // Increment count
         setCount(newCount);
      console.log(count);
        updatedOrders[existingItemIndex] = {
          ...existingOrder,
          count: updatedOrders[existingItemIndex].count + 1, // Increment the count
          totalPrice: (foodDetails.rate + addOnTotal) * (updatedOrders[existingItemIndex].count + 1) // Increment the total price by the item rate
        };
        console.log(updatedOrders[existingItemIndex]);
        return updatedOrders; // Return updated orders
      } else {
        console.log("Adding new item");


        // Update the order to add with the new count
        const newOrder = {
          ...item,
          count: 1,
          totalPrice: (foodDetails.rate + addOnTotal),
          addOns: selectedAddOns,
        };
        return [...prevOrders, newOrder]; // Add the new order to the list
      }
    });
    setSelectedAddOns([]);
  };
  const handleDecrement = () => {
    console.log("hello world");
    if (count > 0) setCount(count - 1);
    setItemRate(item.rate * (count - 1));
  };

  function convertSnakeToText(snakeCaseText) {
    return snakeCaseText
      .split("_") // Split the string by underscores
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()) // Capitalize the first letter of each word
      .join(" "); // Join the words back with spaces
  }

 
  const handleAddOnChange = (addOn) => {
    let updatedAddOns;

    if (selectedAddOns.some((selected) => selected.name === addOn.name)) {
      // Remove the add-on if it's already selected
      updatedAddOns = selectedAddOns.filter(
        (selected) => selected.name !== addOn.name
      );
    } else {
      // Add the new add-on
      updatedAddOns = [...selectedAddOns, addOn];
    }

    setSelectedAddOns(updatedAddOns);

    // Recalculate the item rate after changing add-ons
    const addOnTotal = updatedAddOns.reduce((acc, addon) => {
      return acc + (addon.rate || 0);
    }, 0);

    setItemRate(item.rate * count + addOnTotal);
  };
  console.log(selectedAddOns);

  // Determine items to display (collapsed or expanded view)
  const visibleAddOns = showMore ? addOnChecklist : addOnChecklist.slice(0, 2);

  const calculateTotalPrice = () => {
    const addOnTotal = selectedAddOns.reduce((acc, addon) => {
      return acc + (addon.rate || 0); // Use 0 if price is undefined
    }, 0);
    console.log(addOnTotal);

    // Calculate total price: item price * quantity + add-on total
    return (item.rate * count + addOnTotal).toFixed(2);
  };

  const incrementQuantity = (item) => {
    // Ensure the count is incremented by 1
    const newCount = item.count + 1;

    // Calculate the add-on total once
    const addOnTotal =
      item.addOns?.reduce((acc, addon) => acc + (addon.rate || 0), 0) || 0;

    // Calculate the new total price correctly by applying add-ons once and multiplying by count
    const newTotalPrice = (item.rate + addOnTotal) * newCount;

    // Update localOrders with the new count and new total price
    setLocalOrders((prevOrders) => {
      const existingItemIndex = prevOrders.findIndex(
        (orderItem) =>
          orderItem.id === item.id &&
          JSON.stringify(orderItem.addOns) === JSON.stringify(item.addOns)
      );

      if (existingItemIndex > -1) {
        // If the item exists, update its count and total price
        const updatedOrders = [...prevOrders];
        updatedOrders[existingItemIndex] = {
          ...updatedOrders[existingItemIndex],
          count: newCount, // Update the count
          totalPrice: newTotalPrice, // Update the total price correctly
        };
        return updatedOrders;
      }

      return prevOrders;
    });
  };


  const decrementQuantity = (item) => {
    const newCount = item.count - 1;
    console.log(newCount);

    // If the count is less than 0, do nothing
    if (newCount < 0) return;

    if (newCount === 0) {
      // If the count is zero, dispatch an action to remove the item
   dispatch(removeOrderItem({ id: item.id, addOns: item.addOns }));
   onCloseOrderSummary()
   }

    setLocalOrders((prevOrders) => {
      // Find the item by id and matching add-ons
      const existingItemIndex = prevOrders.findIndex(
        (orderItem) =>
          orderItem.id === item.id &&
          JSON.stringify(orderItem.addOns) === JSON.stringify(item.addOns)
      );

      console.log(existingItemIndex);

      // If the item exists and newCount is 0, remove it from the list
      if (newCount === 0 && existingItemIndex > -1) {
        const updatedOrders = [...prevOrders];
        updatedOrders.splice(existingItemIndex, 1); // Remove the item
        return updatedOrders; // Return the updated orders
      }

      // Otherwise, update the count and total price
      if (existingItemIndex > -1) {
        const addOnTotal =
          item.addOns?.reduce((acc, addon) => acc + (addon.rate || 0), 0) || 0;
        const newTotalPrice = (item.rate + addOnTotal) * newCount;

        const updatedOrders = [...prevOrders];
        updatedOrders[existingItemIndex] = {
          ...updatedOrders[existingItemIndex],
          count: newCount,
          totalPrice: newTotalPrice,
        };
        return updatedOrders;
      }

      return prevOrders; // If no match found, return previous orders as is
    });
  };


  const handleCancelClick = () => {
    onClose();
  };

  // Check if the current item is present in the order list
  const orderItem = orders.find((order) => order.id === item.id);

  const useLocalImage = foodDetails.icon_name && foodDetails.icon_name.includes('ic');

  return (
    <Modal
      open={open}
      onClose={onClose}
      aria-labelledby="modal-title"
      aria-describedby="modal-description"
    >
      <Box
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: "90%",
          maxWidth: "400px",
          bgcolor: "background.paper",
          borderRadius: "12px",
          boxShadow: 24,
          p: 4,
          overflow: "auto",
          maxHeight: "80vh", // Set the max height of the modal
          overflowY: "auto",
        }}
      >

{useLocalImage ? (
        // <Avatar
        //   src={icon} // Use `item.icon` if provided, else fallback to default icon
        //   variant="rounded"
        //   sx={{
        //     width: 76,
        //     height: 76,
        //     marginBottom: '12px',
        //     margin: '0 auto',
        //     marginTop: '25px',
        //   }}
        // />
        <IconHandler iconPath={foodDetails.icon_name}
         fallbackIcon={icon}
         altText={foodDetails.name}
         style={{
           width: 76,
           height: 76,
           marginBottom: '12px',
           margin: '0 auto',
           marginTop: '25px',
         }}
         />
      ) : (
        <S3ImageAmplify
          imageKey={item.icon_name}
          grpUserId={item.user_id}
          altText="S3 Image"
          style={{
            width: 76,
            height: 76,
            marginBottom: '12px',
            margin: '0 auto',
            marginTop: '25px',
          }}
        />
      )}

        <Box sx={{ borderBottom: "1px solid #E5E7EB", margin: "10px 0" }} />
        <Grid container spacing={1} alignItems="center">
          <Grid item xs={12}>
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <Typography
                variant="h6"
                sx={{ fontWeight: "bold", fontSize: "1.2rem" }}
                id="modal-title"
              >
                {foodDetails.name}
              </Typography>
              {item.item_type && (
                  <Avatar
                  src={
                    item.item_type === "assets/icons/ic_veg.svg"
                      ? vegIcon
                      : item.item_type === "assets/icons/ic_non_veg.svg"
                      ? nonVegIcon
                      : item.item_type === "assets/icons/ic_non_alcoholic.svg"
                      ? nonAlcholicIcon
                      : item.item_type === "assets/icons/ic_alcoholic.svg"
                      ? alcholicIcon
                      : ""
                  }
                  variant="rounded"
                  sx={{ width: 20, height: 20 }}
                />
              )}

            
            </Box>

            <Typography variant="body2" color="text.secondary">
              {foodDetails.status ? convertSnakeToText(item.status) : ""}
            </Typography>
            <Box sx={{ borderBottom: "1px solid #E5E7EB", margin: "10px 0" }} />
            <Typography sx={{ paddingTop: 1.2, fontWeight: "bold" }}>
              {"Item Description"}
            </Typography>
            <Typography variant="body2" color="text.secondary">
              {/* Food Food{" "} */}
              {foodDetails.description || "N/A"}
            </Typography>
          </Grid>
        </Grid>
        <Box sx={{ borderBottom: "1px solid #E5E7EB", margin: "10px 0" }} />
        <Typography
          sx={{ marginTop: "10px", marginBottom: "8px", fontWeight: "bold" }}
        >
          Add-ons:
        </Typography>
        <div>
          {visibleAddOns.map((addOn, index) => (
            // console.log(addOn),
            <label
              key={index}
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                marginBottom: "8px",
                width: "100%",
              }}
            >
              <input
                type="checkbox"
                checked={selectedAddOns.some(
                  (selected) => selected.name === addOn.name
                )}
                onChange={() => handleAddOnChange(addOn)}
              />
              <span style={{ flex: 1, marginLeft: "8px" }}>{addOn.name}</span>
              <span style={{ marginLeft: "16px", whiteSpace: "nowrap" }}>
                {addOn.rate}
              </span>
            </label>
          ))}
          {addOnChecklist.length > 2 && (
            <Button
              onClick={() => setShowMore((prevShowMore) => !prevShowMore)}
              style={{ marginTop: "8px", cursor: "pointer" }}
            >
              {showMore
                ? "Show Less"
                : `Show ${addOnChecklist.length - 2} More`}
            </Button>
          )}
        </div>

        <Box sx={{ borderBottom: "1px solid #E5E7EB", margin: "10px 0" }} />
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            marginTop: "10px",
          }}
        >
          <Box>
      
              <Typography>
                {foodDetails.name ? foodDetails.name : ""}
              </Typography>
              <Typography sx={{ fontSize: "12px" }}>
                {selectedAddOns?.map((addon) => addon.name).join(", ")}
              </Typography>

          </Box>
          <Box sx={{ display: "block", alignItems: "center" }}>
          <Typography
              sx={{
                fontWeight: "bold",
                fontSize: 18,
                color: "blue",
                margin: 0,
                marginLeft: "8px", // Adjust margin
                // paddingBottom: 0,
                padding: "8px 25px", // Adjust padding
              }}
            >
              {/* {`₹ ${calculateTotalPrice()}`}₹ {foodDetails.rate} */}
              ₹{
                (parseInt(foodDetails.rate) + 
                parseInt(selectedAddOns?.reduce((acc, addon) => acc + (addon.rate || 0), 0) || 0))
              }
            </Typography>
            <Button
              variant="outlined"
              size="large" // Change size to large
              // startIcon={<AddIcon />}
              // onClick={handleAddClick}
              onClick={handleIncrement}
              sx={{
                borderRadius: "24px",
                padding: "8px 16px", // Adjust padding
                textTransform: "none",
                fontWeight: "bold",
                color: "#4caf50",
                borderColor: "#4caf50",
                marginLeft: "8px", // Adjust margin
                minHeight: "36px",
                minWidth: "80px", // Adjust min width
                fontSize: "16px", // Adjust font size
              }}
            >
              Add
            </Button>
          </Box>
        </Box>
        <Box sx={{ borderBottom: "1px solid #E5E7EB", margin: "10px 0" }} />

        {localOrders.map((order, index) => (
          <Box key={index} sx={{ marginTop: "10px" }}>
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <Box sx={{ display: "flex", alignItems: "center" }}>
              {item.item_type && (
                <Avatar
                  src={
                    order.item_type === "assets/icons/ic_veg.svg"
                      ? vegIcon
                      : order.item_type === "assets/icons/ic_non_veg.svg"
                      ? nonVegIcon
                      : order.item_type === "assets/icons/ic_non_alcoholic.svg"
                      ? nonAlcholicIcon
                      : order.item_type === "assets/icons/ic_alcoholic.svg"
                      ? alcholicIcon
                      : ""
                  }
                  variant="rounded"
                  sx={{ width: 24, height: 24 }}
                />
                )}
                <Typography sx={{ paddingLeft: 1 }}>{order.name}</Typography>
              </Box>
              <Box>
                <Typography sx={{ color: "blue" }}>
                  {/* ₹{order.totalPrice} */}
                  {(
              order.count *
              (order.rate +
                (order.addOns?.reduce(
                  (acc, addon) => acc + (addon.rate || 0),
                  0
                ) || 0))
            ).toFixed(2)}
                </Typography>
              </Box>
            </Box>

            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <Typography sx={{ paddingLeft: 4 }} variant="body2">
                {order.addOns?.map((addOn) => addOn.name).join(", ")}
              </Typography>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  marginTop: 1,
                }}
              >
                <IconButton
                  onClick={() => decrementQuantity(order)}
                  size="small"
                >
                  <Avatar src={minusButton}  sx={{ borderRadius: 0 }}/>
                </IconButton>
                <Typography sx={{ paddingX: 1 }}>{order.count}</Typography>
                <IconButton
                  onClick={() => incrementQuantity(order)}
                  size="small"
                >
                  <Avatar src={plusButton}  sx={{ borderRadius: 0 }}/>
                </IconButton>
              </Box>
            </Box>
            <Box sx={{ borderBottom: "1px solid #E5E7EB", margin: "10px 0" }} />
          </Box>
        ))}
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            marginTop: "20px", // Adjust margin to space buttons from the order list
          }}
        >
          <Button
            variant="contained"
            sx={{
              borderRadius: 5,
              backgroundColor: "#fff",
              color: "green",
              border: "1px solid green",
            }}
            onClick={handleCancelClick} // Cancel action
          >
            Cancel
          </Button>
          {
            localOrders.length > 0 && (
              <Button
                variant="contained"
                sx={{
                  borderRadius: 5,
                  backgroundColor: "green",
                  color: "#fff",
                  border: "1px solid green",
                }}
                onClick={handleAddClick} // Save action
              >
                Save
              </Button>
            )
          }
          {/* <Button
            variant="contained"
            sx={{
              borderRadius: 5,
              backgroundColor: "green",
              color: "#fff",
              border: "1px solid green",
            }}
            onClick={handleAddClick} // Save action
          >
            Save
          </Button> */}
        </Box>
      </Box>
    </Modal>
  );
};
