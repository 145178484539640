import React, { useState } from "react";
import {
  Box,
  Typography,
  TextField,
  Button,
  IconButton,
  Divider,
  Grid,
  Avatar,
  Dialog,
} from "@mui/material";
import plusButton from "../images/icons/plus.svg";
import Login from "./Login";
import minusButton from "../images/icons/minus.svg";
import Swal from "sweetalert2"; // Make sure to import Swal at the top of your file
import foodIcon from "../images/icons/food.svg"; // Replace with your actual icon path
import { useNavigate } from "react-router-dom";
import backButton from "../images/icons/backIcon.svg";
import { useDispatch, useSelector } from "react-redux";
import {
  clearOrderItems,
  removeOrderItem,
  updateOrderItemCount,
} from "../Redux/store";
import {
  postHostelData,
  putHostelData,
} from "../api/services/FetchNodeService";
import S3ImageAmplify from "../config/S3ImageAmplify";
import { navigateWithHostelParams } from "../components/NavigateWithHostelParams";
import FoodLoader from "../Loader/loading";
import IconHandler from "../components/IconHandler";
import { useHostelContext } from "../context/HostelContext";
const CheckOut = () => {
  const orderItems = useSelector((state) => state.food.orderItems);
  const { hostelId, hostelName, categoryId , userId } = useHostelContext();

  console.log(orderItems);
  const navigate = useNavigate();
  const dispatch = useDispatch(); // Get the dispatch function
  const [isLoginDialogOpen, setIsLoginDialogOpen] = useState(false);

  const [location, setLocation] = useState("");
  const [loading, setLoading] = useState(false);
  const guestPhone = localStorage.getItem("guestPhone");
  const guestName = localStorage.getItem("guestName");

  const sub_category_ids = new Set(
    orderItems.map((item) => item.sub_category_id)
  );

  let sub_category_name = "";
  if (sub_category_ids.has("fnb")) {
    sub_category_name = "Food & Beverages";
  } else if (sub_category_ids.has("exp")) {
    sub_category_name = "Experience & Merchandise";
  }

  console.log(sub_category_name);

  const guestId = localStorage.getItem("guestId");
  const guestStatus = localStorage.getItem("guestStatus");
  console.log(guestStatus);

  const handleLoginSuccess = () => {
    setIsLoginDialogOpen(false);
    console.log("User logged in successfully!");
    // Proceed with order placement or any post-login actions
  };
  const handleCloseLoginDialog = () => {
    setIsLoginDialogOpen(false);
  };

  const onAddMoreItems = () => {
    // Convert the Set to an array and get the first value or use a default value
    const sub_category_id_array = Array.from(sub_category_ids);
    const sub_category_id =
      sub_category_id_array.length > 0
        ? sub_category_id_array[0]
        : localStorage.getItem("parentSubCat"); // Set 'default' as fallback // Set 'default' as fallback

    // navigate(`/categories/${sub_category_id}` , { state: {  label: sub_category_id  } });

    navigateWithHostelParams(navigate, `/categories/${sub_category_id}`, {
      label: sub_category_id,
    });
  };

  const handleQuantityChange = (item, newCount) => {
    if (newCount < 0) return; // Prevent reducing below 0

    if (newCount === 0) {
      // If the count is zero, dispatch an action to remove the item
      dispatch(removeOrderItem({ id: item.id, addOns: item.addOns }));
      // dispatch(removeOrderItem(item.id)); // You need to implement this action in your Redux store
    } else {
      // Calculate the new total price based on count and add-ons
      const addOnTotal = (item?.addOns || []).reduce(
        (acc, addon) => acc + (addon.rate || 0),
        0
      );
      const newTotalPrice = (item.rate + addOnTotal) * newCount;
      console.log(newTotalPrice);

      // Dispatch action to update count and totalPrice
      dispatch(
        updateOrderItemCount({
          ...item,
          count: newCount,
          totalPrice: newTotalPrice,
        })
      );
    }
  };

  const formatDate = (date) => {
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0"); // Add 1 because months are 0-indexed
    const day = String(date.getDate()).padStart(2, "0");
    return `${year}-${month}-${day}`;
  };

  let token = localStorage.getItem("TOKEN");

  const handleSubmit = async () => {
    try {
      const token = localStorage.getItem("TOKEN");
      if (!token) {
        setIsLoginDialogOpen(true); // Open login dialog if user is not authenticated
        return;
      }

      // Check if orderItems is empty
      if (!orderItems || orderItems.length === 0) {
        Swal.fire({
          icon: "warning",
          title: "No items to order",
          text: "Please add items to your order before submitting.",
        });
        return;
      }

      setLoading(true); // Start loading
      const today = formatDate(new Date());
      const billed_items = orderItems.map((item) => {
        const addOnTotal =
          item.addOns?.reduce((acc, addon) => acc + (addon.rate || 0), 0) || 0;
        return {
          id: item.id,
          name: item.name,
          price: item.rate,
          quantity: item.count,
          add_on_checklist: item.addOns,
        };
      });

      const total_amount = billed_items.reduce((sum, item) => {
        const addOnTotal =
          item.add_on_checklist?.reduce(
            (acc, addon) => acc + (addon.rate || 0),
            0
          ) || 0;
        return sum + (item.price + addOnTotal) * item.quantity;
      }, 0);

      const orderPayload = {
        category_id: categoryId || localStorage.getItem('categoryId'),
        sub_category_id: sub_category_name,
        hostel_id: hostelId || localStorage.getItem('hostel_id'),
        user_id:  userId || localStorage.getItem('user_id'),
        billed_item: billed_items,
        balance_due: total_amount,
        date: today,
        received: 0.0,
        guest_id: guestId,
        total_amount: total_amount,
        status: guestStatus,
        description: location,
      };

      const updateGuestPayload = {
        null_folio: false,
      };

      // Ensure both API calls succeed
      const [orderResponse, updateResponse] = await Promise.all([
        postHostelData("account/create-pos-order", orderPayload),
        putHostelData(`account/guest/${guestId}`, updateGuestPayload),
      ]);

      if (!orderResponse.error && !updateResponse.error) {
        // Show success pop-up
        Swal.fire({
          icon: "success",
          title: "Your order has been placed successfully",
          showConfirmButton: false,
          timer: 1500,
        });
        console.log(
          "Order placed successfully:",
          orderResponse,
          updateResponse
        );

        // Clear Redux store and navigate
        dispatch(clearOrderItems());
        navigateWithHostelParams(navigate, `/food-menu`);
      } else {
        throw new Error(
          orderResponse.message ||
            updateResponse.message ||
            "Failed to place order"
        );
      }
    } catch (error) {
      console.error("Error placing order:", error);
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "Something went wrong while placing your order!",
      });
    } finally {
      setLoading(false); // Stop loading
    }
  };

  return (
    <>
      {loading && <FoodLoader loading={loading} />}
      <Box sx={{ padding: "16px", marginBottom: "60px", textAlign: "left" }}>
        {" "}
        {/* Margin bottom to avoid overlap with fixed footer */}
        <Grid
          container
          alignItems="center"
          justifyContent="space-between"
          sx={{ marginBottom: "16px" }}
        >
          <IconButton>
            <IconButton onClick={() => navigate(-1)}>
              <Avatar
                src={backButton}
                alt="Back"
                sx={{ width: 24, height: 24 }}
              />
            </IconButton>
          </IconButton>
          <Typography
            variant="h6"
            sx={{ fontFamily: "DM Sans", fontWeight: 700 }}
          >
            {hostelName}
          </Typography>
          <IconButton>{/* <Sear /> */}</IconButton>
        </Grid>
        {/* Property Name and Guest Details */}
        <Box sx={{ marginBottom: "16px" }}>
          <Box sx={{ marginTop: "8px", marginBottom: "8px" }}>
            <Typography variant="subtitle1">Guest Details</Typography>
            <Typography variant="body2">
              Phone Number:&nbsp;
              {/* {guestDetails.phoneNumber} */ guestPhone}
            </Typography>
            <Typography variant="body2">
              Name:&nbsp;
              {/* {guestDetails.name} */ guestName}
            </Typography>
          </Box>
          <Divider />
        </Box>
        {/* Selected Items */}
        <Box sx={{ marginBottom: "16px" }}>
          <Typography variant="subtitle1" sx={{ fontWeight: "bold" }}>
            Selected Items
          </Typography>
          <Button
            onClick={onAddMoreItems}
            variant="outlined"
            sx={{ marginTop: "8px", marginBottom: "8px", width: "100%" }}
          >
            + Add More Items
          </Button>
          {orderItems.map((item, index) => {
            const useLocalImage =
              item.icon_name && item.icon_name.includes("ic");
            return (
              <Box
                key={index}
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                  padding: "8px",
                  borderRadius: "8px",
                  border: "1px solid #ccc",
                  marginBottom: "8px",
                  backgroundColor: "#FFF",
                }}
              >
                <Box sx={{ display: "flex", alignItems: "center" }}>
                  {useLocalImage ? (
                    <IconHandler
                      iconPath={item.icon_name}
                      fallbackIcon={foodIcon}
                      altText={item.name}
                      style={{
                        width: "40px",
                        height: "40px",
                        marginRight: "12px",
                      }}
                    />
                  ) : (
                    <S3ImageAmplify
                      imageKey={item.icon_name}
                      grpUserId={item.user_id}
                      altText={item.name}
                      style={{
                        width: "40px",
                        height: "40px",
                        marginRight: "12px",
                      }}
                    />
                  )}
                  <Box sx={{ width: "50%", flexShrink: 0 }}>
                    <Typography>{item.name}</Typography>
                    <Typography
                      variant="body2"
                      color="textSecondary"
                      sx={{
                        display: "inline",
                        whiteSpace: "pre-wrap", // Allows line breaks
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                      }}
                    >
                      {item.addOns?.map((addOn) => addOn.name).join(" ")}
                    </Typography>
                  </Box>
                </Box>
                <Box sx={{ display: "block", mx: 2 }}>
                  <Box sx={{ display: "flex" }}>
                    <IconButton
                      onClick={() => handleQuantityChange(item, item.count - 1)}
                    >
                      <Avatar
                        src={minusButton}
                        sx={{ width: 24, height: 24, borderRadius: 0 }}
                      />
                    </IconButton>
                    <Typography sx={{ padding: "8px", textAlign: "center" }}>
                      {item.count}
                    </Typography>
                    <IconButton
                      onClick={() => handleQuantityChange(item, item.count + 1)}
                    >
                      <Avatar
                        src={plusButton}
                        sx={{ width: 24, height: 24, borderRadius: 0 }}
                      />
                    </IconButton>
                  </Box>
                  <Box>
                    <Box sx={{ width: 80, textAlign: "right" }}>
                      <Typography variant="body2" fontWeight="medium">
                        ₹ 
                        {/* {((item?.count || 0) * (item?.rate || 0)).toFixed(2)} */}
                        {(
              item?.count *
              (item?.rate +
                (item.addOns?.reduce(
                  (acc, addon) => acc + (addon?.rate || 0),
                  0
                ) || 0))
            ).toFixed(2)}
                      </Typography>
                    </Box>
                  </Box>
                </Box>
              </Box>
            );
          })}
        </Box>
        {/* Delivery Details */}
        {sub_category_name == "Food & Beverages" ? (
          <Box sx={{ marginBottom: "16px" }}>
            <Typography variant="subtitle1">
              Where should we serve your order?
            </Typography>
            <TextField
              fullWidth
              placeholder="Please type here e.g. Room No: 101"
              onChange={(e) => {
                setLocation(e.target.value);
              }}
              sx={{ marginTop: "8px" }}
            />
          </Box>
        ) : (
          <></>
        )}
        {/* Action Buttons */}
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            marginTop: "16px",
          }}
        >
          <Button
            variant="contained"
            color="primary"
            sx={{
              flex: 1,
              backgroundColor: token ? "#2FA551" : "primary.main",
              "&:hover": {
                backgroundColor: token ? "#249b43" : "primary.dark", // Add hover effect
              },
            }}
            onClick={handleSubmit}
          >
            {/* Place Order */}
            {token ? "Place Order" : "Login to Place Order"}
          </Button>
        </Box>
      </Box>
      <Dialog
        open={isLoginDialogOpen}
        onClose={() => setIsLoginDialogOpen(false)}
      >
        <Login
          onLoginSuccess={handleLoginSuccess}
          onClose={handleCloseLoginDialog}
        />
      </Dialog>
    </>
  );
};

export default CheckOut;
