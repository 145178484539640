import React, { createContext, useState, useContext } from 'react';

const GuestContext = createContext();

export const GuestProvider = ({ children }) => {
  const [guestName, setGuestName] = useState(localStorage.getItem('guestName') || '');
  const [guestPhone, setGuestPhone] = useState(localStorage.getItem('guestPhone') || '');
  const [token, setToken] = useState(localStorage.getItem('TOKEN') || '');

  const updateGuestName = (name) => {
    setGuestName(name);
    localStorage.setItem('guestName', name);
  };

  const updateGuestPhone = (phone) => {
    setGuestPhone(phone);
    localStorage.setItem('guestPhone', phone);
  };

  const updateToken = (token) => {
    setToken(token);
    localStorage.setItem('TOKEN', token);
  };

  return (
    <GuestContext.Provider
      value={{
        guestName,
        guestPhone,
        token,
        updateGuestName,
        updateGuestPhone,
        updateToken,
      }}
    >
      {children}
    </GuestContext.Provider>
  );
};

export const useGuestContext = () => useContext(GuestContext);
